import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        leaderboard: [],
        entry: {
            feedback_question: null,
            feedback_answer: null,
            attempt: 0
        }
    },

    getters: {
        all: state => state.entry,
        get: state => pProps =>
        {
            return state.entry[ pProps ];
        },

        feedbackQuestion: state => state.feedback_question,
        feedbackAnswer: state => state.entry.feedback_answer,
        attempt: state => state.entry.attempt,

        leaderboard: state => state.leaderboard
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        setLeaderboard: ( state, payload ) =>
        {
            state.leaderboard = payload;
        },

        updateAttempt: state =>
        {
            state.entry.attempt++;
        }
    },

    actions: {
        newAttempt: context =>
        {
            context.commit( 'updateAttempt' );
        },

        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        getLeaderboard: context =>
        {
            return new EntryProxy()
                .all()
                .then( response =>
                {
                    context.commit( 'setLeaderboard', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        update: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            payload._method = 'PUT';

            return new EntryProxy()
                .update( context.getters.get( 'id' ), payload )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
