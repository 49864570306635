export default {
    namespaced: true,

    state: {
        result: 'no-prize',
        level: 0,
        highestScore: 0
    },

    getters: {
        result: state => state.result,
        level: state => state.level,
        highestScore: state => state.highestScore
    },

    mutations: {
        setPrize( state, payload )
        {
            state.result = payload;
        },

        setLevel( state, payload )
        {
            state.level = payload;
            if( state.level > state.highestScore )
            {
                state.highestScore = state.level;
            }
        }
    },

    actions: {
        setPrize( context, payload )
        {
            context.commit( 'setPrize', payload );
        },

        setLevel( context, payload )
        {
            context.commit( 'setLevel', payload );
        }
    }
};
