import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

VuexRouterSync.sync( store, router );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

Vue.config.productionTip = false;

store.dispatch( 'initStore' );

// Subscribe to store updates
store.subscribe( ( mutation, state ) =>
{
    // Store the state object as a JSON string
    window.localStorage.setItem( 'store', JSON.stringify( state ) );
});

// store.dispatch( 'setFeedbackQuestion', window._rootData.feedbackQuestion );
store.dispatch( 'entry/merge', {
    feedback_question: window._rootData.feedbackQuestion,
    difficulty_increase: window._rootData.levelDifficultyFactorIncrease,
    start_speed: window._rootData.startSpeed,
    max_speed: window._rootData.maxSpeed
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
