import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht`, // `${obj.path} is default`
                oneOf: obj => `${obj.label} is verplicht`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                fullname: {
                    // name: 'firstname',
                    name: 'firstname',
                    placeholder: 'jouw naam...',
                    autocomplete: 'given-name',
                    tabindex: 1
                },

                email: {
                    name: 'email',
                    placeholder: 'jouw@email.com',
                    type: 'email',
                    tabindex: 2
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 3
                },

                agree: {
                    type: 'checkbox',
                    name: 'agree',
                    id: 'agree-checkbox',
                    tabindex: 4
                }
            },

            validationSchema: yup.object().shape({
                fullname: yup.string()
                    .label( 'naam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-\s])+$/ )
                    .min( 2 ),
                email: yup.string()
                    .label( 'e-mail' )
                    .required()
                    .email( 'Dit is geen valide mailadres' ),
                agree: yup.mixed()
                    .label( 'deze checkbox' )
                    .oneOf( [true] )
            })
        };
    }
};
