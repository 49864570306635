import Vue from 'vue';
import Router from 'vue-router';

// import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    base: window._rootData.contextPath,
    routes: [
        {
            path: '/landing',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/videoguide',
            name: 'videoguide',
            component: () =>
            {
                return import( /* webpackChunkName: "videoguide" */ '@/app/pages/VideoGuide' );
            }
        },
        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            }
        },
        {
            path: '/score',
            name: 'Score',
            component: () =>
            {
                return import( /* webpackChunkName: "score" */ '@/app/pages/Score' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                // if( !from.name )
                // {
                //     next( '/' );
                // }

                next();
            }
        },
        {
            path: '/result',
            name: 'Result',
            component: () =>
            {
                return import( /* webpackChunkName: "result" */ '@/app/pages/Result' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                // if( !store.getters[ 'entry/get' ]( 'id' ) )
                // {
                //     next( '/' );
                //     return;
                // }
                next();
            }
        },
        {
            path: '/barcode',
            name: 'Barcode',
            component: () =>
            {
                return import( /* webpackChunkName: "barcode" */ '@/app/pages/Barcode' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                // if( !store.getters[ 'entry/get' ]( 'id' ) )
                // {
                //     next( '/' );
                //     return;
                // }
                next();
            }
        },
        {
            path: '/feedback',
            name: 'Feedback',
            component: () =>
            {
                return import( /* webpackChunkName: "feedback" */ '@/app/pages/Feedback' );
            }
        },
        // generated-routes
        {
            path: '/:wildcard?/:wildcard2?',
            name: 'Redirect',
            beforeEnter: ( to, from, next ) =>
            {
                next( '/landing' );
            }
            // component: () =>
            // {
                // return import( /* webpackChunkName: "attention" */ '@/app/pages/Attention' );
            // }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    // if( !from.name && to.name === 'Attention' )
    // {
    //     if( to.path !== '/' )
    //     {
    //         store.dispatch( 'entry/merge', { origin: to.path });
    //     }
    // }

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
